import "./style";
import { h, Fragment } from "preact";
import { useState } from "preact/hooks";
import date from "date-and-time";

const locations = [
  {
    name: "Metrostation Wilheminaplein",
    distance: 1,
  },
  {
    name: "Metrostation Slinge",
    distance: 13.5,
  },
  {
    name: "Metrostation Zuidplein",
    distance: 18.5,
  },
  {
    name: "Metrostation Maashaven",
    distance: 23.7,
  },
  {
    name: "Metrostation Wilheminaplein (2)",
    distance: 26.4,
  },
  {
    name: "Metrostation Beurs",
    distance: 28.2,
  },
  {
    name: "Metrostation Blaak",
    distance: 28.8,
  },
  {
    name: "Metrostation Blaak (2)",
    distance: 41,
  },
  {
    name: "Metrostation Beurs (2)",
    distance: 41.6,
  },
];

const Start = ({ start, setStart }) => {
  const increment = () => setStart(date.addMinutes(start, 1));
  const decrement = () =>
    setStart((currentStart) => date.addMinutes(currentStart, -1));

  return (
    <div>
      <p>
        <strong>Start:</strong> {date.format(start, "HH:mm")}
      </p>
      <button onClick={increment}>Increment +</button>
      <button onClick={decrement}>Decrement -</button>
    </div>
  );
};

const Pace = ({ pace, setPace }) => {
  const increment = () => setPace(date.addSeconds(pace, 1));
  const decrement = () =>
    setPace((currentPace) => date.addSeconds(currentPace, -1));

  return (
    <div>
      <p>
        <strong>Pace:</strong> {date.format(pace, "m:ss")}
      </p>
      <button onClick={increment}>Increment +</button>
      <button onClick={decrement}>Decrement -</button>
    </div>
  );
};

const List = ({ start, pace }) => {
  const minutes = Number(date.format(pace, "mm"));
  const seconds = Number(date.format(pace, "ss"));

  const calculator = (distance) => {
    return date.addSeconds(
      date.addMinutes(start, minutes * distance),
      seconds * distance
    );
  };

  const duration = (start, end) => {
    const difference = (end - start) / 1000;
    const measuredTime = new Date(null);
    measuredTime.setSeconds(difference);
    return measuredTime.toISOString().substr(11, 8);
  };

  const items = Array.from(Array(42)).map((item, index) =>
    calculator(index + 1)
  );

  return (
    <div>
      <p>
        <strong>Duration:</strong> {duration(start, calculator(42.195))}
      </p>
      <p>
        <strong>Finish:</strong> {date.format(calculator(42.195), "HH:mm")}
      </p>
      <details>
        <summary>Splits</summary>
        <table>
          <thead>
            <tr>
              <th>Distance</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr>
                <td>{index + 1} km</td>
                <td>{date.format(item, "HH:mm")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </details>
      <details>
        <summary>Locations</summary>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Distance</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {locations.map((item, index) => (
              <tr>
                <td>{item.name}</td>
                <td>{item.distance} km</td>
                <td>{date.format(calculator(item.distance), "HH:mm")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </details>
    </div>
  );
};

export default function App() {
  const [start, setStart] = useState(
    date.parse("10-04-2022 10:00", "DD-MM-YYYY HH:mm")
  );
  const [pace, setPace] = useState(date.parse("4:15", "m:ss"));

  const reset = () => {
    setStart(date.parse("10-04-2022 10:00", "DD-MM-YYYY HH:mm"));
    setPace(date.parse("4:15", "m:ss"));
  };

  return (
    <Fragment>
      <header>
        <h1>Rotterdam Marathon 2022</h1>
        <p>
          <a
            target="_blank"
            href="https://track.rtrt.me/e/RM-2022#/tracker/RSHXJDV3"
          >
            https://track.rtrt.me/e/RM-2022#/tracker/RSHXJDV3
          </a>
        </p>
      </header>
      <main>
        <Start start={start} setStart={setStart} />
        <Pace pace={pace} setPace={setPace} />
        <List start={start} pace={pace} />
        <img src="assets/parcours.png"></img>
        <button onClick={reset}>Reset</button>
      </main>
      <footer>
        <p>Created by Yoni Stap</p>
      </footer>
    </Fragment>
  );
}
